import "./styles.css";
import { useHistory } from "react-router-dom";

function TextPage() {
  const history = useHistory();

  return (
    <section className="pt-5 disclaimer-page-wrapper section is-flex is-flex-direction-column is-justify-content-space-between">
      <div className="back-button">
        <button onClick={() => history.goBack()} className="trans-button button is-small">
          <i className="link-icon fas fa-arrow-left" aria-hidden="true"></i>
          go back
        </button>
      </div>
      <div className="container inner-container my-4">
        <h1 className="title mb-0">Disclaimer</h1>
        <div className="hr-block mb-5 mt-1" />
        <p>
          The content of this website and it's creator are by no means denying the existence of COVID-19, promoting
          anti-vaccination, supporting unfounded conspiracy theories or encouraging anyone to break the law in any way.
        </p>
        <p>
          We fully acknowledge the hard work our front-line workers are doing during this time, and do not seek to
          discredit anyone, or any business, that is suffering during these trying times.
        </p>
        <p>
          We wholly encourage everyone to think critically about the evidence surrounding lockdown, it's consequences,
          and most importantly to take responsibility for your own health and to support each other in your communities.
        </p>
      </div>
      <div />
    </section>
  );
}

export default TextPage;
