import "./styles.css";

const ScrollUpButton = ({ onClick }) => {
  return (
    <div className="scrollTopButton" onClick={onClick}>
      <i className="scroll-icon fas fa-arrow-circle-up" aria-hidden="true"></i>
    </div>
  );
};

export default ScrollUpButton;
