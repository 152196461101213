import "./styles.css";
import { useHistory } from "react-router-dom";

function Donate() {
  const history = useHistory();

  return (
    <section className="pt-5 mission-page-wrapper section is-flex is-flex-direction-column is-justify-content-space-between">
      <div className="back-button">
        <button onClick={() => history.goBack()} className="trans-button button is-small">
          <i className="link-icon fas fa-arrow-left" aria-hidden="true"></i>
          go back
        </button>
      </div>
      <div className="container inner-container my-4">
        <h1 className="title mb-0">Donate</h1>
        <div className="hr-block mb-5 mt-1" />
        <p>
          Help our cause by donating whatever amount you can afford—any amount would be greatly appreciated. Money
          raised will be put towards legal efforts, advertising, advocacy, website and operational costs.
        </p>
        <p>
          We are not a registered charity, contributions are not tax deductible, and we do not offer tax receipts.
        </p>
        <div className="donor-box-container">
          <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
          <iframe
            title="lockdowns-fail-donation"
            allowpaymentrequest=""
            frameborder="0"
            height="900px"
            name="donorbox"
            scrolling="no"
            seamless="seamless"
            src="https://donorbox.org/embed/end-lockdowns-in-ontario"
            width="100%"
            style={{
              maxWidth: '500px',
              minWidth: '250px',
              maxHeight: 'none'
            }}
          >
          </iframe>
        </div>
      </div>
      <div />
    </section>
  );
}

export default Donate;
