import Disclaimer from "./pages/Disclaimer";
import Mission from "./pages/Mission";
import Home from "./pages/Home";
import ContactMPP from "./pages/ContactMPP";
import Donate from "./pages/Donate";

import { HashRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/disclaimer">
          <Disclaimer />
        </Route>
        <Route path="/mission">
          <Mission />
        </Route>
        <Route path="/contact-your-mpp">
          <ContactMPP />
        </Route>
        <Route path="/donate">
          <Donate />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
