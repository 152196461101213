import { useState, useEffect } from "react";
import "./styles.css";
import content from "../../content.js";
import logo from "../../endlockdownlogo.png";
import { useHistory } from "react-router-dom";
import ScrollUpButton from "../../components/ScrollUpButton";
import SearchBar from "../../components/SearchBar";

function Home() {
  const history = useHistory();
  const visitLink = (link) => {
    window.open(link, "_blank");
  };
  const [tags, setTags] = useState([
    {
      slug: "research",
      active: true,
      title: "Research Article",
    },
    {
      slug: "expert",
      active: true,
      title: "Expert Commentary",
    },
    {
      slug: "data",
      active: true,
      title: "Data",
    },
    {
      slug: "discussion",
      active: true,
      title: "Discussion",
    },
    {
      slug: "leader",
      active: true,
      title: "Leader Hypocrisy",
    },
    {
      slug: "consequence",
      active: true,
      title: "Lockdown Consequence",
    },
  ]);

  const [articles, setArticles] = useState(content);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [searchText, setSearchText] = useState("");

  const checkScrollTop = () => {
    if (!showScrollButton && window.pageYOffset > 500) {
      setShowScrollButton(true);
    } else if (showScrollButton && window.pageYOffset <= 500) {
      setShowScrollButton(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const activeTags = tags.filter((tag) => tag.active).map((tag) => tag.slug);
    const updatedArticles = content.filter((article) => {
      return (
        activeTags.includes(article.type) &&
        (article.title.toLowerCase().includes(searchText.toLowerCase()) ||
          article.content.toLowerCase().includes(searchText.toLowerCase()))
      );
    });
    setArticles(updatedArticles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, searchText]);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", checkScrollTop);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const toggleTagFilter = (selectedTag) => {
    const updatedTags = tags.map((tag) => {
      if (selectedTag.slug === tag.slug) {
        return { ...tag, active: !tag.active };
      }
      return tag;
    });

    setTags(updatedTags);
  };

  return (
    <div className="page-wrapper">
      <section className="lockdown-header hero is-light is-bold">
        <div className="hero-body py-4">
          <div className="container max-width">
            <h1 className="title">
              <img className="hero-logo" alt="End Lockdown Logo" src={logo} />
              End Lockdown
            </h1>
            <h2 className="subtitle mb-4">
              Evidence, as well as the opinions of medical experts and professionals, suggests that lockdowns are an
              ineffective strategy for controlling COVID-19. Furthermore, the damage caused by lockdown is well
              documented and out-weighs any potential benefits a lockdown may bring. End the unscientific, undemocratic,
              and damaging lockdown measures in place here in Ontario, Canada.
            </h2>
            <div>
              <button
                onClick={() => history.push("/disclaimer")}
                className="mr-2 mb-2 trans-button disclaimer-button button is-small"
              >
                <i className="link-icon fas fa-exclamation-circle" aria-hidden="true"></i>
                disclaimer
              </button>
              <button
                onClick={() => history.push("/mission")}
                className="mr-2 mb-2 trans-button disclaimer-button button is-small"
              >
                <i className="link-icon fas fa-fist-raised" aria-hidden="true"></i>
                mission statement
              </button>
              <button
                onClick={() => history.push("/contact-your-mpp")}
                className="mr-2 mb-2 trans-button disclaimer-button button is-small"
              >
                <i className="link-icon fab fa-canadian-maple-leaf" aria-hidden="true"></i>
                contact your MPP
              </button>
              <button
                onClick={() => history.push("/donate")}
                className="mb-5 trans-button disclaimer-button button is-small"
              >
                <i className="link-icon fas fa-donate" aria-hidden="true"></i>
                donate
              </button>
            </div>
            <p className="icon-text mb-1">
              <span className="icon is-size-7">
                <i className="far fa-hand-point-right"></i>
              </span>
              <span className="is-size-7">click the tags to filter the articles</span>
            </p>
            {tags.map((tag) => (
              <span
                key={tag.slug}
                onClick={() => toggleTagFilter(tag)}
                className={`tag ${tag.slug}-tag ${tag.active ? "" : "inactive"}`}
              >
                {tag.title}
              </span>
            ))}
            <SearchBar
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              placeholder="Search articles"
            />
          </div>
        </div>
      </section>

      <div className="container max-width">
        <div className="columns">
          <div className="column is-half is-flex is-flex-direction-column is-align-items-center">
            {articles.map((article, index) => {
              if (index % 2 !== 0) {
                return null;
              }
              return (
                <section key={article.title} className="article-wrapper">
                  <div className={`box ${article.type}`}>
                    <h3 className="title">{article.title}</h3>
                    <p className="subtitle">{article.content}</p>
                    <button onClick={() => visitLink(article.link)} className="trans-button button is-small">
                      <i className="link-icon fa fa-link" aria-hidden="true"></i>
                      {article.linkText}
                    </button>
                  </div>
                </section>
              );
            })}
          </div>
          <div className="column is-half is-flex is-flex-direction-column is-align-items-center">
            {articles.map((article, index) => {
              if (index % 2 === 0) {
                return null;
              }
              return (
                <section key={article.title} className="article-wrapper">
                  <div className={`box ${article.type}`}>
                    <h3 className="title">{article.title}</h3>
                    <p className="subtitle">{article.content}</p>
                    <button onClick={() => visitLink(article.link)} className="trans-button button is-small">
                      <i className="link-icon fa fa-link" aria-hidden="true"></i>
                      {article.linkText}
                    </button>
                  </div>
                </section>
              );
            })}
          </div>
        </div>
      </div>
      {!!showScrollButton && <ScrollUpButton onClick={scrollTop} />}
    </div>
  );
}

export default Home;
