const content = [
  {
    title:
      "A country level analysis measuring the impact of government actions, country preparedness and socioeconomic factors",
    content:
      "In our analysis, full lockdowns and wide-spread COVID-19 testing were not associated with reductions in the number of critical cases or overall mortality.",
    link: "https://www.thelancet.com/journals/eclinm/article/PIIS2589-5370(20)30208-X/fulltext",
    linkText: "https://www.thelancet.com",
    type: "research",
  },
  {
    title: "A medic’s case against another lockdown",
    content:
      "Dr. Matt Strauss (former medical director of the critical care unit at Guelph General Hospital, current assistant professor of medicine at Queen’s University) speaks out against lockdown. If lockdowns were a prescription drug for Covid-19 treatment, the FDA would never have approved it.",
    link: "https://www.spectator.co.uk/article/a-medic-s-case-against-another-lockdown",
    linkText: "https://www.spectator.co.uk",
    type: "expert",
  },
  {
    title: "The Great Barrington Declaration",
    content:
      "As infectious disease epidemiologists and public health scientists we have grave concerns about the damaging physical and mental health impacts of the prevailing COVID-19 policies, and recommend an approach we call Focused Protection.",
    link: "https://gbdeclaration.org/",
    linkText: "https://gbdeclaration.org",
    type: "expert",
  },
  {
    title: "Prof. Sucharit Bhakdi on PCR testing",
    content:
      "Dr. Bhakdi states that PCR testing should not be used for it's current purpose of diagnosing a disease. When a PCR test comes back positive, that person is labelled as a COVID-19 case, even though this does not mean they are infected.",
    link: "https://www.youtube.com/watch?v=oZreyzYo0Bs",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "COVID-19: Lockdowns are Unscientific",
    content:
      "Canada, and more specifically the government of Ontario, has implemented non-evidence-based draconian lockdown measures that are not proportionate to the apparent danger presented by COVID-19. In this article I will present research from several authors, look at the data surrounding the impact of COVID-19 and take a look at the consequences of a forced lockdown.",
    link: "https://attilakomaromi.medium.com/covid-19-lockdowns-are-unscientific-f3505d4c7914",
    linkText: "https://medium.com",
    type: "discussion",
  },
  {
    title: "Population-level COVID-19 mortality risk for non-elderly individuals",
    content:
      "The vast majority of the population may be reassured that their risks are very low. Long-term lockdowns may have major adverse consequences for health (suicides, domestic violence, worsening mental health, cardiovascular disease, loss of health insurance from unemployment, and famine, to name a few) and also creating new major issues for society at large.",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7327471/",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Covid-19 Mortality: A Matter of Vulnerability Among Nations",
    content:
      "Stringency of the measures settled to fight pandemia, including lockdown, did not appear to be linked with death rate. Countries that already experienced a stagnation or regression of life expectancy, with high income and NCD rates, had the highest price to pay. This burden was not alleviated by more stringent public decisions.",
    link: "https://www.frontiersin.org/articles/10.3389/fpubh.2020.604339/full",
    linkText: "https://www.frontiersin.org",
    type: "research",
  },
  {
    title: "COVID19 is a seasonal climate-driven disease",
    content:
      "Data from both hemispheres and the second wave provide opportunities to further examine climatic drivers. With a statistical method designed to detect transitory associations, we show consistent negative effects of temperature and absolute humidity at large spatial scales.",
    link: "https://www.medrxiv.org/content/10.1101/2020.12.16.20248310v1",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "Seasonality of COVID-19 - Europe and USA Explained",
    content:
      "Biochemical engineer and Technical Manager describes the seasonality of COVID-19 while referencing publicly available data. He also describes the negative consequences and uselessness of lockdowns.",
    link: "https://www.youtube.com/watch?v=3cjgicrA504&feature=youtu.be",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Household Transmission of SARS-CoV-2",
    content:
      "The secondary attack rate for symptomatic index cases was 18.0%, and the rate of asymptomatic and presymptomatic index cases was 0.7%. The asymptomatic/ presymptomatic secondary attack rate is not statistically different from zero.",
    link:
      "https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2774102?utm_campaign=articlePDF&utm_medium=articlePDFlink&utm_source=articlePDF&utm_content=jamanetworkopen.2020.31756",
    linkText: "https://jamanetwork.com",
    type: "research",
  },
  {
    title: "Herd immunity for COVID-19",
    content:
      "Closing schools, for example, can have serious consequences that last a lifetime. The USA has seen almost 100,000 excess deaths this year from conditions other than COVID-19. A large proportion of those excess deaths are due to various aspects of the lockdown. If you are not in a vulnerable group, the collateral damage of lockdown is far more destructive than the virus.",
    link: "https://www.thelancet.com/journals/lanres/article/PIIS2213-2600(20)30555-5/fulltext",
    linkText: "https://www.thelancet.com",
    type: "research",
  },
  {
    title: "Oncologist fears 'tsunami of cancer' after COVID-19 lockdowns limited screening",
    content:
      "This drop in diagnoses means there are people out there walking around in B.C. who have cancer but don't know it, says Chi. With less screening, there are fewer early diagnoses so people are presenting with more advanced cancers that are harder to treat successfully.",
    link: "https://www.cbc.ca/news/health/cancer-tsunami-screening-delays-covid-1.5844708",
    linkText: "https://www.cbc.ca",
    type: "consequence",
  },
  {
    title: "The Decimal Point that Blew Up the World",
    content:
      "Editorial Director for the American Institute for Economic Research explains the difference between IFR and CFR, and puts forth evidence that suggests COVID-19 is not as deadly as we were told. The lockdowners who created this appalling disaster, the people who turned our trust into betrayal and a blizzard of statistical baloney, need to look at the science and data as they stand and come clean.",
    link: "https://www.aier.org/article/the-decimal-point-that-blew-up-the-world/",
    linkText: "https://www.aier.org",
    type: "discussion",
  },
  {
    title: "Public and official data surrounding COVID-19 in Ontario",
    content:
      "The average age of a death by or with Covid-19 is higher than life expectancy. However, Covid-19 is often not a wholly distinct and separate cause of death as there are other medical conditions involved in many cases. There are also cases in which it is not even a cause of death, but the individual had tested positive sometime prior to their death. Overall, the data suggests that there is no significant uptick in deaths compared to previous years/ months, and no spike in deaths that is inconsistent from previous trends.",
    link: "https://twitter.com/Milhouse_Van_Ho/status/1354643620205355010",
    linkText: "https://twitter.com",
    type: "data",
  },
  {
    title: "Estimating the true magnitude of the pandemic",
    content:
      "Lockdown will cause more deaths in the future than COVID-19. That lockdowns kill more people than COVID was also acknowledged in a 15 July 2020 report by the UK Department of Health and Social Care, Office for National Statistics, Government Actuary’s Department and Home Office which noted that when morbidity is taken into account, the estimates for the health impacts from a lockdown and lockdown induced recession are greater in terms of QALYs than the direct COVID-19 deaths.",
    link:
      "https://timesofindia.indiatimes.com/blogs/seeing-the-invisible/estimating-the-true-magnitude-of-the-pandemic-and-lockdown-deaths-part-1/?fbclid=IwAR0Z6kwwwWfh0plxfQsoTYwyX2vRdaq6fd9k4INlKrEITmx-6AryzTB_ENo",
    linkText: "https://timesofindia.indiatimes.com",
    type: "discussion",
  },
  {
    title: "WHO rewrites definition of herd immunity",
    content:
      "What is herd immunity? According to the WHO, the answer no longer includes immunity through previous infection, but only through vaccination.",
    link: "https://twitter.com/DrEliDavid/status/1341760075757740035",
    linkText: "https://twitter.com",
    type: "discussion",
  },
  {
    title: "One-third of Ontario businesses report they will not survive the second lockdown",
    content:
      "Ontario has now expanded the most anti-small business lockdown measure in the country. No other province in Canada closes small businesses while allowing big-box stores to sell similar goods in-store. Our members are dumbfounded as to why their government continues to feel that it is safer to buy a book, bracelet or pair of shoes at Walmart or Costco than to buy the same items at their quiet, local independent retailer.",
    link:
      "https://www.cfib-fcei.ca/en/media/news-releases/one-third-ontario-businesses-report-they-will-not-survive-second-lockdown",
    linkText: "https://www.cfib-fcei.ca",
    type: "consequence",
  },
  {
    title: "Study: Suicidal behavior in youths higher during COVID-19 closures than in 2019",
    content:
      "Significantly higher rates of suicide-related behaviors appear to have corresponded with times when COVID-19 stressors and community responses (e.g., stay-at-home orders and school closures) were heightened, indicating that youth experienced elevated distress during these periods.",
    link: "https://www.aappublications.org/news/2020/12/16/pediatricssuicidestudy121620",
    linkText: "https://www.aappublications.org",
    type: "research",
  },
  {
    title: "Longterm effects",
    content:
      "For those still claiming the “longterm effects” are unique to covid-19 haven’t been paying attention to other viral illnesses. Let’s take a look...",
    link: "https://twitter.com/HeckofaLiberal/status/1287549725567197184",
    linkText: "https://twitter.com",
    type: "discussion",
  },
  {
    title: "Deaths in Sweden",
    content:
      "What is the current mortality in Sweden (a country which avoided mask and lockdown restrictions), and how does it compare to the past?",
    link: "https://legionsletters.com/mortality#SWEDEN-MORTALITY",
    linkText: "https://legionsletters.com",
    type: "data",
  },
  {
    title: "Why did Sweden have more covid deaths than its neighbors?",
    content:
      "Correlation is not causation. Many people have chosen to see a causative relationship between Sweden’s lack of severe lockdown and relatively high number of deaths, because it supports their prior beliefs about the effectiveness of lockdowns. Those beliefs are, however, not supported by the evidence.",
    link: "https://sebastianrushworth.com/2020/12/06/why-did-sweden-have-more-covid-deaths-than-its-neighbors/",
    linkText: "https://sebastianrushworth.com",
    type: "expert",
  },
  {
    title: "Asymptomatic Spread Revisited",
    content:
      "We keep hearing about how we should follow the science. The claim is tired by now. We know what’s really happening. The lockdown lobby ignores whatever contradicts their narrative, preferring unverified anecdotes over an actual scientific study of 10 million residents in what was the world’s first major hotspot for the disease we are trying to manage.",
    link: "https://www.aier.org/article/asymptomatic-spread-revisited/",
    linkText: "https://www.aier.org",
    type: "discussion",
  },
  {
    title: "Natural coronavirus immunity could be TWICE as common as experts thought",
    content:
      "The researchers found that none of those with high T-cell responses became infected with Covid in the following four months – suggesting this part of the immune system is an effective protective factor.",
    link: "https://www.dailymail.co.uk/news/article-8968265/Are-25-immune-Covid-19.html",
    linkText: "https://www.dailymail.co.uk",
    type: "research",
  },
  {
    title: "Vitamin D and COVID 19",
    content:
      "Professor Roger Seheult, MD explains the important role Vitamin D may have in the prevention and treatment of COVID-19. Dr. Seheult illustrates how Vitamin D works, summarizes the best available data and clinical trials on vitamin D, and discusses vitamin D dosage recommendations.",
    link: "https://www.youtube.com/watch?v=ha2mLz-Xdpg",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Covid deaths per million",
    content:
      "The COVID-19 Second wave has reached most of the countries hailed as successful lockdowns in the first one.",
    link: "https://i.redd.it/2sh4tl1fjs661.png",
    linkText: "https://i.redd.it",
    type: "data",
  },
  {
    title: "Dr. Roger Hodkinson's advice to Edmonton",
    content:
      "Dr. Roger Hodkinson offers his informed perspective to Edmonton’s City Council on how governments responded to COVID19. He has called for the end of restrictions.",
    link: "https://www.facebook.com/watch/?v=284868239575668",
    linkText: "https://www.facebook.com",
    type: "expert",
  },
  {
    title: "Why most Covid-19 deaths won’t be from the virus",
    content:
      "Across the globe, patients have reported being denied cancer care, kidney dialysis and urgent transplant surgeries, with sometimes fatal results. In the Balkans, women have been driven to try dangerous, experimental abortions themselves, while experts in the UK have reported a rise in DIY dentistry, as people turn to toe-curling improvisations.",
    link: "https://www.bbc.com/future/article/20200528-why-most-covid-19-deaths-wont-be-from-the-virus",
    linkText: "https://www.bbc.com",
    type: "consequence",
  },
  {
    title: "Does lockdown prevent covid deaths?",
    content:
      "Medical Doctor explains that lockdowns don't prevent deaths. The authors found no correlation whatsoever between severity of lockdown and number of covid deaths. And they didn’t find any correlation between border closures and covid deaths either. And there was no correlation between mass testing and covid deaths either, for that matter. Basically, nothing that various world governments have done to combat covid seems to have had any effect whatsoever on the number of deaths.",
    link: "https://sebastianrushworth.com/2020/11/09/does-lockdown-prevent-covid-deaths/",
    linkText: "https://sebastianrushworth.com",
    type: "expert",
  },
  {
    title: "Lockdown will claim the equivalent of 560,000 lives",
    content:
      "Lockdowns will end up claiming the equivalent of more than 500,000 lives because of the health impact of the 'deep and prolonged recession it will cause', a leading expert claimed last night.",
    link:
      "https://www.dailymail.co.uk/news/article-8925425/Lockdown-claim-equivalent-560-000-lives-health-impact-recession-cause.html",
    linkText: "https://www.dailymail.co.uk",
    type: "consequence",
  },
  {
    title: "Sweden mortality data",
    content: "In Sweden, all cause mortality for 2020 is at one of it’s lowest points in recorded history.",
    link: "https://twitter.com/CMacdon12/status/1345549644219105280",
    linkText: "https://twitter.com",
    type: "data",
  },
  {
    title: "Comparison of Illinois and Indiana",
    content: "Comparison of Illinois and Indiana suggests that lockdown was ineffective.",
    link: "https://twitter.com/OBusybody/status/1345912922770386944",
    linkText: "https://twitter.com",
    type: "data",
  },
  {
    title: "Dr. Yaffe on testing Ontario teachers",
    content:
      " If you're testing a population that doesn't have very much COVID, you'll get false positives almost half the time—that is the person doesn't have COVID—they may have something else, or they may have nothing, so it will just complicate the picture.",
    link: "https://www.youtube.com/watch?v=3COXVbP5WdI&feature=youtu.be",
    linkText: "https://www.youtube.com",
    type: "discussion",
  },

  {
    title: "Effects of non-pharmaceutical interventions on COVID-19",
    content:
      "Researchers analyzed the effectiveness of lockdown and found that lockdown had little or no benefit as it was typically introduced at a point when the time-varying reproductive number was already very low. Claimed benefits of lockdown appear grossly exaggerated.",
    link: "https://www.medrxiv.org/content/10.1101/2020.07.22.20160341v3",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "San Francisco Sees More Overdose Deaths Than Covid Deaths in 2020",
    content:
      "Data show alarming trends in drug overdoses and suicide as people—especially young people who are least at risk from COVID-19—are forcibly cut off from friends, families, and communities.",
    link:
      "https://fee.org/articles/san-francisco-sees-more-overdose-deaths-than-covid-deaths-in-2020/?utm_source=email&utm_medium=email&utm_campaign=2020_FEEDaily",
    linkText: "https://fee.org",
    type: "consequence",
  },
  {
    title: "A study on infectivity of asymptomatic SARS-CoV-2 carriers",
    content:
      "In summary, all the 455 contacts were excluded from SARS-CoV-2 infection and we conclude that the infectivity of some asymptomatic SARS-CoV-2 carriers might be weak.",
    link: "https://pubmed.ncbi.nlm.nih.gov/32513410/",
    linkText: "https://pubmed.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "The Science behind COVID19 and lockdown",
    content: "Jay Bhattacharya, medical doctor and Stanford PhD economist, reviews the current science on COVID-19.",
    link: "https://www.youtube.com/watch?v=-ESXdVbIhmg",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Open Schools, Covid-19, and Child and Teacher Morbidity in Sweden",
    content:
      "Despite Sweden having kept schools and preschools open, we found a low incidence of severe Covid-19 among schoolchildren and children of preschool age during the SARS-CoV-2 pandemic.",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMc2026670",
    linkText: "https://www.nejm.org",
    type: "research",
  },
  {
    title: "Anonymous NHS doctor on lockdown",
    content:
      "Anonymous NHS doctors explains why they don't think lockdown is the answer. The medical profession does not speak with one voice and there are many, who despite knowing full well the awful realities of COVID, do not agree with lockdowns.",
    link:
      "https://www.reddit.com/r/LockdownSkepticism/comments/kpylk8/as_an_nhs_doctor_i_dont_believe_the_lockdowns_are/",
    linkText: "https://www.reddit.com",
    type: "discussion",
  },
  {
    title: "Did Lockdown Work? An Economist’s Cross-Country Comparison",
    content:
      "Comparing weekly mortality in 24 European countries, the findings in this paper suggest that more severe lockdown policies have not been associated with lower mortality. In other words, the lockdowns have not worked as intended.",
    link:
      "https://www.datascienceassn.org/sites/default/files/Did%20Lockdown%20Work%20An%20Economist%E2%80%99s%20Cross-Country%20Comparison.pdf",
    linkText: "https://www.datascienceassn.org",
    type: "research",
  },
  {
    title: "COVID Tests Gone Wild—An Epidemic of COVID Positive Tests",
    content:
      "Our health authorities are recommending more testing of asymptomatic people. In other words, they are encouraging the wrong and lousy application of these tests. Our health officials are doing what a first-year medical student should know better than to do.",
    link: "https://internationalman.com/articles/covid-tests-gone-wild-an-epidemic-of-covid-positive-tests/",
    linkText: "https://internationalman.com",
    type: "expert",
  },
  {
    title: "Canadian expert's research finds lockdown harms are 10 times greater than benefits",
    content:
      "It turns out that loneliness and unemployment are known to be among the strongest risk factors for early mortality, reduced lifespan, and chronic diseases. I think that the initial modelling and forecasting were inaccurate. This led to a contagion of fear and policies across the world. I believe that we need to take an “effortful pause” and reconsider the information available to us.",
    link:
      "https://torontosun.com/opinion/columnists/canadian-experts-research-finds-lockdown-harms-are-10-times-greater-than-benefits?fbclid=IwAR0IxVOqct_Ad_PkSAfK2rf2ZmAcmFbD-YYyHX1EvBQrPCPFSknJl27aH7I",
    linkText: "https://torontosun.com",
    type: "expert",
  },
  {
    title: "Senior bureaucrat overseeing border and travel health accepted Air Canada junket to Jamaica",
    content:
      "Dominique Baker, a senior public servant at the Public Health Agency of Canada, accepted an all-expenses-paid holiday to Jamaica courtesy of Air Canada Vacations in November, even as her agency has been advising Canadians since March to avoid non-essential travel.",
    link:
      "https://www.theglobeandmail.com/politics/article-senior-bureaucrat-overseeing-border-and-travel-health-accepted-air/",
    linkText: "https://www.theglobeandmail.com",
    type: "leader",
  },
  {
    title: "Ontario hospital CEO who vacationed in Dominican Republic resigns",
    content:
      "The CEO of Ontario's St. Joseph's Health System in Hamilton and Niagara Health vacationed in the Caribbean over the holidays despite government advisories to stay home as the number of COVID-19 cases soared across the province.",
    link: "https://www.cbc.ca/news/canada/hamilton/st-joes-niagara-health-ceo-vacation-covid-19-1.5862449",
    linkText: "https://www.cbc.ca",
    type: "leader",
  },
  {
    title: "London, Ont. hospital CEO apologizes for international travel",
    content:
      "LHSC confirms that Woods, who is a Canadian citizen but holds a green card in the U.S., has travelled there five times since March 2020, including from Dec. 19-25, 2020, just before the provincial lockdown took effect.",
    link:
      "https://london.ctvnews.ca/mobile/london-ont-hospital-ceo-apologizes-for-international-travel-during-pandemic-1.5259383?fbclid=IwAR2hjOE2626d8tZYK1RQs3E2bBI8Fny1-qWDnDJ5u3RAZxN9t_YJgW8m1-c#_gus&_gucid=&_gup=Facebook&_gsc=ufYvdRU",
    linkText: "https://london.ctvnews.ca",
    type: "leader",
  },
  {
    title: "COVID-19: Rethinking the Lockdown Groupthink",
    content:
      "The public health goal of lockdowns was to save the population from COVID-19 cases and deaths, and to prevent overwhelming health care systems with COVID-19 patients. In this narrative review I explain why I changed my mind about supporting lockdowns.",
    link: "https://www.preprints.org/manuscript/202010.0330/v2",
    linkText: "https://www.preprints.org",
    type: "research",
  },
  {
    title: "Calgary police under fire for the violent arrest of hockey player",
    content:
      "Officers threatened to “fucking faze” Ocean and there were a series of failed struggles before they were able to take him to the ground, cut off his skates and haul him away.",
    link:
      "https://www.westernstandardonline.com/2020/12/calgary-police-under-fire-for-the-violent-arrest-of-hockey-player/",
    linkText: "https://www.westernstandardonline.com",
    type: "consequence",
  },
  {
    title: "'We are all essential': Newmarket business charged after defying lockdown",
    content:
      "Given the high survival rates for anyone infected with COVID-19 under 65, the owners argued that the hospitals and public health officials had not adequately justified the additional restrictions they see as unnecessary and harmful to small businesses.",
    link:
      "https://www.newmarkettoday.ca/coronavirus-covid-19-local-news/we-are-all-essential-newmarket-business-charged-after-defying-lockdown-3222459",
    linkText: "https://www.newmarkettoday.ca",
    type: "consequence",
  },
  {
    title: "York's top doctor hopeful region can avoid lockdown",
    content:
      "The top public health official in York Region says that he does not believe further restrictions are necessary to curb the spread of COVID-19 in his community. Additional restrictions that would accompany a formal lockdown designation, such as the outright closure of bars, restaurants, gyms and personal care services, may not have much effect in York.",
    link:
      "https://www.cp24.com/news/york-s-top-doctor-hopeful-region-can-avoid-lockdown-even-as-some-indicators-suggest-it-could-be-imminent-1.5216055?cache=dkorkzbx",
    linkText: "https://www.cp24.com",
    type: "discussion",
  },
  {
    title: "Nurses say sick pay a barrier to stopping spread",
    content:
      "Some people on the front-line may be afraid to call in sick if they have symptoms. Front-line workers may not get paid after getting COVID—if a worker is sick and not full-time status, they are not getting paid.",
    link: "https://www.cp24.com/video?clipId=2117297&cache=dkorkzbx",
    linkText: "https://www.cp24.com",
    type: "discussion",
  },
  {
    title:
      "Peer reviewed literature covering wet-lab experiments, in silico analysis of the Corman Drosten protocol-design (PCR)",
    content:
      "We present 20 scientific publications providing ‘wet lab’ evidence of the performance of the Corman et al. PCR protocol. Of those, 17 found problems with incorrect primer design (mismatches, dimer formation, melting temperature) in the SARS-CoV-2 specific “confirmatory” test named RdRp-PCR for “RNA-dependent RNA-polymerase” or the E-gene assay.",
    link: "https://cormandrostenreview.com/addendum/",
    linkText: "https://cormandrostenreview.com",
    type: "research",
  },
  {
    title: "Sweden death rate",
    content:
      "The figures are taken from the Official Statistics of Sweden (SCB). They show no significant increase in deaths, with 2020 have an identical death rate to 2015.",
    link: "https://imgur.com/a/IWcagQ7",
    linkText: "https://imgur.com",
    type: "data",
  },
  {
    title: "In the Asian Flu of 1957-58, They Rejected Lockdowns",
    content:
      "Globally, it might have been five times as deadly as Covid-19, as measured by deaths per capita. Public health experts did in fact consider school closures, business closures, and a ban of public events but the entire ethos of the profession rejected them. There were two grounds for this rejection: lockdowns would be too disruptive, disabling the capacity of medical professionals to deal competently with the crisis, and also because such policies would be futile because the virus was already here and spreading.",
    link: "https://www.aier.org/article/in-the-asian-flu-of-1957-58-they-rejected-lockdowns/",
    linkText: "https://www.aier.org",
    type: "discussion",
  },
  {
    title: "Assessing mandatory stay-at-home and business closure effects on the spread of COVID-19",
    content:
      "While small benefits cannot be excluded, we do not find significant benefits on case growth of more restrictive non-pharmaceutical interventions (NPIs). Similar reductions in case growth may be achievable with less restrictive interventions.",
    link: "https://onlinelibrary.wiley.com/doi/abs/10.1111/eci.13484",
    linkText: "https://onlinelibrary.wiley.com",
    type: "research",
  },
  {
    title: "Time for a more balanced approach, say Ontario doctors concerned with restrictions",
    content:
      "Several Ontario doctors are speaking out to call for a more balanced approach to managing the pandemic. Instead, Dr. Rau (an infectious diseases specialist and assistant professor at the University of Toronto) urges government to throw more resources at the health care system, create capacity and build more field hospitals. Rau points out that the hospital system has been overwhelmed in previous years due to a bad influenza season. We didn’t close every restaurant and gym and control people’s lives like this.",
    link:
      "https://torontosun.com/news/provincial/time-for-a-more-balanced-approach-say-ontario-doctors-concerned-with-restrictions",
    linkText: "https://torontosun.com",
    type: "discussion",
  },
  {
    title: "The West should envy Japan’s COVID-19 response",
    content:
      "Japan attracted world notice for neither imposing a lockdown nor obsessively testing asymptomatic people. There is little empirical data to support the abstract mathematical models on which governments relied to clamp lockdowns. The virus is not unprecedented, but the draconian societal shutdowns are.",
    link:
      "https://www.japantimes.co.jp/opinion/2021/01/10/commentary/japan-commentary/west-japan-coronavirus-response/",
    linkText: "https://www.japantimes.co.jp",
    type: "discussion",
  },
  {
    title: "Covid: Child abuse referrals up nearly 80%, says NSPCC",
    content:
      "The number of referrals from the NSPCC about child abuse has increased by 79% since the UK-wide lockdown was imposed, according to the charity's data. There was an average of 115 referrals from helpline calls made each month between April and November, compared to the pre-lockdown average of 64.",
    link: "https://www.bbc.com/news/uk-wales-55352968",
    linkText: "https://www.bbc.com",
    type: "consequence",
  },
  {
    title: "Interdisciplinary research scientist, Denis Rancourt, on the topic of Covid-19",
    content:
      "The government response has killed many vulnerable people. That you're not having human contact—social isolation—is a parameter that has been shown that, for the same viral infection, you have a much higher chance of suffering a violent infection and dying if you're socially isolated.",
    link: "https://www.youtube.com/watch?v=cRUT7PajZr0&feature=youtu.be",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Was Germany’s Corona Lockdown Necessary?",
    content:
      "Official data from Germany’s RKI agency suggest strongly that the spread of the corona virus in Germany receded autonomously, before any interventions become effective. Several reasons for such an autonomous decline have been suggested. One is that differences in host susceptibility and behavior can result in herd immunity at a relatively low prevalence level.",
    link:
      "https://advance.sagepub.com/articles/preprint/Comment_on_Dehning_et_al_Science_15_May_2020_eabb9789_Inferring_change_points_in_the_spread_of_COVID-19_reveals_the_effectiveness_of_interventions_/12362645",
    linkText: "https://advance.sagepub.com",
    type: "research",
  },
  {
    title: "The illusory effects of non-pharmaceutical interventions on COVID-19 in Europe",
    content:
      "We demonstrate that the United Kingdom’s lockdown was both superfluous and ineffective. Sweden, the only country in the dataset that refrained from strong measures, but has lower corona deaths per capita than Belgium, Italy, Spain, or the United Kingdom.",
    link:
      "https://www.datascienceassn.org/sites/default/files/Illusory%20Effects%20of%20Non-pharmaceutical%20Interventions%20on%20COVID19%20in%20Europe.pdf",
    linkText: "https://www.datascienceassn.org",
    type: "research",
  },
  {
    title: "Elderly people more lonely in wake of COVID-19",
    content:
      "Our recent study into the impact of COVID-19 on the elderly has found a huge increase in loneliness since the lockdown was put in place. Over a three-month period, a lack of any kind of break from the four walls around you may cause serious long-term health damage, both physically and mentally.",
    link:
      "https://www.datascienceassn.org/sites/default/files/Illusory%20Effects%20of%20Non-pharmaceutical%20Interventions%20on%20COVID19%20in%20Europe.pdf",
    linkText: "https://www.datascienceassn.org",
    type: "consequence",
  },
  {
    title: "The Impact of Social Isolation and Loneliness on the Mental Health of Children",
    content:
      "The rapid review suggests that loneliness that may result from disease containment measures in the COVID-19 context could be associated with subsequent mental health problems in young people. Strategies to prevent the development of such problems should be an international priority.",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7267797/",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Montreal police stop woman going to work during curfew and search her lunch bag",
    content:
      "The officer threatened her with a ticket, saying the letter is just a piece of paper, and accused her of delaying detainment by being uncooperative and refusing to show him the bag.",
    link:
      "https://theprovince.com/news/canada/montreal-police-stop-woman-going-to-work-during-curfew-and-search-her-lunch-bag/wcm/5e6cebd3-48b2-4a65-8201-62b2dc12ef4a?utm_medium=Social&utm_source=Facebook&fbclid=IwAR3iA1JNw4JqWaATN1TYpljnRKCgmdIWTMeB-d8QOdwwaBCODbnpFWy4zyE#Echobox=1610651584",
    linkText: "https://theprovince.com",
    type: "consequence",
  },
  {
    title: "Social isolation, loneliness in older people pose health risks",
    content:
      "Research has linked social isolation and loneliness to higher risks for a variety of physical and mental conditions: high blood pressure, heart disease, obesity, a weakened immune system, anxiety, depression, cognitive decline, Alzheimer’s disease, and even death.",
    link: "https://www.nia.nih.gov/news/social-isolation-loneliness-older-people-pose-health-risks",
    linkText: "https://www.nia.nih.gov",
    type: "consequence",
  },
  {
    title: "Canada's COVID-19 strategy is an assault on the working class",
    content:
      "Despite heroic efforts by the public, the nine-month lockdown and contact tracing strategy has tragically failed older Canadians, with 97% of COVID-19 deaths inflicting those over 60. Where it did “succeed” was in shifting the COVID-19 burden from affluent professionals to the less affluent working class.",
    link:
      "https://torontosun.com/opinion/columnists/opinion-canadas-covid-19-strategy-is-an-assault-on-the-working-class",
    linkText: "https://torontosun.com",
    type: "discussion",
  },
  {
    title: "Full lockdown policies in Western Europe countries have no evident impacts on the COVID-19 epidemic",
    content:
      "Comparing the trajectory of the epidemic before and after the lockdown, we find no evidence of any discontinuity in the growth rate, doubling time, and reproduction number trends. We also show that neighboring countries applying less restrictive social distancing measures (as opposed to police-enforced home containment) experience a very similar time evolution of the epidemic.",
    link: "https://www.medrxiv.org/content/10.1101/2020.04.24.20078717v1",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "The end of exponential growth: The decline in the spread of coronavirus",
    content:
      "Given that the evidence reveals that the Corona disease declines even without a complete lockdown, it is recommendable to reverse the current policy and remove the lockdown.",
    link: "https://www.timesofisrael.com/the-end-of-exponential-growth-the-decline-in-the-spread-of-coronavirus/",
    linkText: "https://www.timesofisrael.com",
    type: "data",
  },
  {
    title: "Too Little of a Good Thing: A Paradox of Moderate Infection Control",
    content:
      "The link between limiting pathogen exposure and improving public health is not always so straightforward. Reducing the risk that each member of a community will be exposed to a pathogen has the attendant effect of increasing the average age at which infections occur. For pathogens that inflict greater morbidity at older ages, interventions that reduce but do not eliminate exposure can paradoxically increase the number of cases of severe disease by shifting the burden of infection toward older individuals.",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2652751/",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Smart Thinking, Lockdown and COVID-19: Implications for Public Policy",
    content:
      "The link between limiting pathogen exposure and improving public health is not always so straightforward. Reducing the risk that each member of a community will be exposed to a pathogen has the attendant effect of increasing the average age at which infections occur. For pathogens that inflict greater morbidity at older ages, interventions that reduce but do not eliminate exposure can paradoxically increase the number of cases of severe disease by shifting the burden of infection toward older individuals.",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2652751/",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Covid-19: How does Belarus have one of the lowest death rates in Europe?",
    content:
      "Belarus’s beleaguered government remains unfazed by covid-19. President Aleksander Lukashenko, who has been in power since 1994, has flatly denied the seriousness of the pandemic, refusing to impose a lockdown, close schools, or cancel mass events like the Belarusian football league or the Victory Day parade. Yet the country’s death rate is among the lowest in Europe.",
    link: "https://www.bmj.com/content/370/bmj.m3543",
    linkText: "https://www.bmj.com",
    type: "research",
  },
  {
    title: "Police on Guard for Thee: retired and active duty peace officers looking to see the end of lockdown",
    content:
      "For 10 months public officials and bureaucrats have been operating with arbitrary emergency powers that often violate the fundamental freedoms guaranteed by the Charter of Rights and Freedoms. Everyday Premier Ford, Mayor Tory, and politically appointed bureaucrats are on television, radio, and in the newspapers, calling on Ontarians to slowly allow all civil liberties to be taken away.",
    link: "https://www.facebook.com/PoliceOnGuardForThee/photos/a.103334925014938/103330161682081/",
    linkText: "https://www.facebook.com",
    type: "discussion",
  },
  {
    title: "Lockdown Effects on Sars-CoV-2 Transmission – The evidence from Northern Jutland",
    content:
      "Our analysis shows that while infection levels decreased, they did so before the mandate was announced, and the restrictions also had limited and statistically insignificant effects relative to neighbour municipalities without mandates, where infection levels also decreased markedly in Aalborg, the most important municipality not subject to lockdown. A direct spill-over effect to neighbour municipalities was not seen.",
    link: "https://www.medrxiv.org/content/10.1101/2020.12.28.20248936v1",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "Ontario finance minister's vacation",
    content:
      "I served in cabinet for four years. I find it impossible to believe that a senior cabinet minister would leave the country and that a premier or premier's office wouldn't know. If they didn't know, they're incompetent. If they did know, they're not being honest with the people of Ontario, neither outcome is actually acceptable.",
    link:
      "https://toronto.ctvnews.ca/ford-admits-he-knew-of-ontario-finance-minister-s-vacation-two-weeks-ago-regrets-not-asking-him-to-return-1.5248576",
    linkText: "https://toronto.ctvnews.ca",
    type: "leader",
  },
  {
    title: "Horror and heartbreak in the wake of Whitby long-term care outbreak",
    content:
      "The families are scheduled to testify before the commission on Thursday. They have three things in common, grief, anger, and the fact they all lost loved ones during the outbreak at Sunnycrest Nursing Home, where 118 out of 119 residents came down with COVID, and 34 died. A provincial investigation conducted as the outbreak raged found “widespread failures” caused “actual harm to residents.” Other findings: no one was screened upon entry, PPE was improperly used, residents weren’t being fed or given medication on time, their wounds untreated.",
    link: "https://www.680news.com/2021/01/13/horror-and-heartbreak-in-the-wake-of-whitby-long-term-care-outbreak/",
    linkText: "https://www.680news.com",
    type: "consequence",
  },
  {
    title: "COVID-19 may eventually be no worse than childhood cold, study finds",
    content:
      "COVID-19 is known to cause life-threatening, and sometimes fatal, outcomes today. But in the future, contracting the virus may be akin to coming down with a common childhood cold. Using data from other human coronaviruses, including SARS-CoV-1, researchers from Emory University and Pennsylvania State University have determined that, eventually, COVID-19 will resemble other common childhood infections.",
    link:
      "https://wgntv.com/news/covid-19-may-eventually-be-no-worse-than-childhood-cold-study-finds/?utm_medium=referral&utm_campaign=socialflow&utm_source=facebook.com&fbclid=IwAR1fvjfY7_jPYESyxOQwG1FY_L3H28WFDD8qa9u8PGSW1M1LPeC44LDbWUg",
    linkText: "https://wgntv.com",
    type: "discussion",
  },
  {
    title: "Longitudinal variability in mortality predicts Covid-19 deaths",
    content:
      "It was in fact possible to predict high death rates caused by covid-19 even before the pandemic hit Europe simply by looking at excess mortality in a country during the preceding normal influenza seasons. Why then are some countries apparently unaffected year after year by fatal respiratory viral disease, while others suffer considerably? Possible explanations include geographical factors, population demographics, and density, genetic factors, and cultural differences along with the organization of health care and elderly nursing homes.",
    link: "https://www.medrxiv.org/content/10.1101/2020.12.25.20248853v1",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "Government mandated lockdowns do not reduce Covid-19 deaths",
    content:
      "Lockdowns do not reduce Covid-19 deaths. This pattern is visible on each date that key lockdown decisions were made in New Zealand. The apparent ineffectiveness of lockdowns suggests that New Zealand suffered large economic costs for little benefit in terms of lives saved.",
    link: "https://www.tandfonline.com/doi/abs/10.1080/00779954.2020.1844786?journalCode=rnzp20",
    linkText: "https://www.tandfonline.com",
    type: "research",
  },
  {
    title: "Huawei CFO Meng's family granted federal travel exemption to visit Canada",
    content:
      "Conservative shadow minister for immigration Raquel Dancho called the decision outrageous and says “the Liberals seemingly rolled out the red carpet to allow Meng Wanzhou’s family to fly into Canada and be reunited for the holidays.”",
    link:
      "https://www.ctvnews.ca/mobile/canada/huawei-cfo-meng-s-family-granted-federal-travel-exemption-to-visit-canada-1.5265236?cid=sm%3Atrueanthem%3Actvnews%3Apost&utm_campaign=trueAnthem%3A+Trending+Content&utm_medium=trueAnthem&utm_source=facebook&fbclid=IwAR0J6qZIPtIXnVpLaPlHWTlBeJBgrjkwyEhFXnBFXzCclRQId2bSNIrMY2o",
    linkText: "https://www.ctvnews.ca",
    type: "leader",
  },
  {
    title: "Doctors urge local approach, not sweeping lockdown, in letter to Ford",
    content:
      "Lockdowns have been shown not to eliminate the virus. While they slow the spread of the virus, this only lasts as long as the lockdown lasts. This creates a situation where there is no way to end the lockdown, and society cannot move forward in vitally important ways including in the health sector, the economy and other critically important instrumental goods including education, recreation, and healthy human social interactions. Lockdowns have costs that have, to this point, not been included in the consideration of further measures. Hard data now exist showing the significant negative health effects shutting down society has caused.",
    link:
      "https://www.healthing.ca/opinion/columnists/doctors-urge-localized-approach-not-sweeping-lockdown-in-open-letter-to-doug-ford/wcm/2a19968b-6421-4707-9e80-2112cddeba3f",
    linkText: "https://www.healthing.ca",
    type: "expert",
  },
  {
    title: "Social Relationships and Health: A Flashpoint for Health Policy",
    content:
      "Captors use social isolation to torture prisoners of war—to drastic effect. Social isolation of otherwise healthy, well-functioning individuals eventually results in psychological and physical disintegration, and even death. Solid scientific evidence shows that social relationships affect a range of health outcomes, including mental health, physical health, health habits, and mortality risk.",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3150158/",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Facts That Every Person Should Know",
    content:
      "Post by Toronto resident Samad Kadri detailing the data, research, and opinions regarding COVID-19 and lockdown here in Canada and more specifically in Ontario—all fully referenced.",
    link: "https://www.facebook.com/502738951/posts/10158274391903952/?d=n",
    linkText: "https://www.facebook.com",
    type: "discussion",
  },
  {
    title: "Some of Ontario's biggest hospitals are filled beyond capacity nearly every day—before Covid",
    content:
      "Hospitals being over capacity, and the “hallway medicine” problem, has been a major problem well before the pandemic. An expert on hospital administration calls the figures “astonishing” and says they demonstrate that overcrowding is now a widespread phenomenon around Ontario. The number of acute care hospital beds in Ontario has stayed effectively the same for 20 years, at around 20,000 while the province's population has grown by three million people. Although the occupancy data analyzed by CBC News comes from the first half of 2019, there's little if any indication the situation has improved since then.",
    link:
      "https://www.cbc.ca/news/canada/toronto/ontario-hospital-hallway-medicine-healthcare-beyond-capacity-1.5420434",
    linkText: "https://www.cbc.ca",
    type: "discussion",
  },
  {
    title: "“Gathering” In Delhi Was A Mother Picking Up Her Children From Grandparents",
    content:
      "A mother dropping her kids off at their grandparent’s in order to pick up groceries is facing charges. She told us that she has taken time off work to help with virtual learning, and with her significant others’ work schedule, she has been dropping her children off at the grandparents in order to run to the store and get items for the family.",
    link:
      "https://www.norfolktoday.ca/2021/01/14/update-gathering-in-delhi-was-a-mother-picking-up-her-children-from-grandparents/",
    linkText: "https://www.norfolktoday.ca",
    type: "consequence",
  },
  {
    title: "Facing another retirement home lockdown, 90-year-old chooses medically assisted death",
    content:
      "Residents eat meals in their rooms, have activities and social gatherings cancelled, family visits curtailed or eliminated. Sometimes they are in isolation in their small rooms for days. Dr. Samir Sinha stated “I do appreciate that this family has come forward, especially when the balance of evidence out there actually says that these restrictions, in too many circumstances, are overly restrictive and actually causing unnecessary harm.”",
    link:
      "https://www.ctvnews.ca/health/facing-another-retirement-home-lockdown-90-year-old-chooses-medically-assisted-death-1.5197140",
    linkText: "https://www.ctvnews.ca",
    type: "consequence",
  },
  {
    title: "Hospital in Newmarket, Ont. to cut 95 registered nurse positions due to financial challenges",
    content:
      "A hospital in Newmarket, Ont. says it is planning to cut 95 registered nurse positions due to financial challenges. If we are having a healthcare crises, why is our government not stepping up to at least cover financial challenges for hospitals? Vicki McKenna, president of the Ontario Nurses' Association, said in a news release on Tuesday that the layoffs will devastate Newmarket and directly affect patient care.",
    link: "https://www.cbc.ca/news/canada/toronto/southlake-regional-health-centre-registered-nurses-layoffs-1.5734673",
    linkText: "https://www.cbc.ca",
    type: "consequence",
  },
  {
    title: "Ontario MPP's letter to Ford, asking to end lockdowns",
    content:
      "Ontario's Hospital and ICU capacity are better than in the last 3 years. The lockdown isn't working. It's causing an avalanche of suicides, overdoses, bankruptcies, divorces and takes an immense toll on our children. With all LTC residents in red zones to be vaccinated by January 21, Ontario should end the lockdown and the catastrophic toll it's causing Ontarians.",
    link: "https://twitter.com/Roman_Baber/status/1350039239090601984/photo/1",
    linkText: "https://twitter.com",
    type: "discussion",
  },
  {
    title: "Dr Richard Schabas, former Ontario Chief Medical Officer, letter to Ford gainst lockdowns",
    content:
      "Our well-informed but misguided efforts to control Covid are only compounding the tragedy. We need to change course. Lockdown was never part of our planned pandemic response nor is it supported by strong science.",
    link: "https://twitter.com/OnCall4ON/status/1351219150945976328/photo/1",
    linkText: "https://twitter.com",
    type: "expert",
  },
  {
    title: "Severe acute respiratory syndrome: Did quarantine help?",
    content:
      "Mass quarantine for disease control was essentially abandoned last century. Does it deserve a second look? Did quarantine work for SARS? Notwithstanding the conclusions of the Toronto public health group, I think the evidence is now overwhelming that quarantine played little or no role in controlling SARS. Furthermore, mass quarantine, as practiced in Toronto, did considerable harm by sapping public health resources and fueling public anxiety.",
    link:
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2094974/?fbclid=IwAR2XDTwHF7QMFY2-LsBP0wpM5Lmd_-PbufppQV-mCEY0ieTeiF_iul2psq8",
    linkText: "https://www.ncbi.nlm.nih.gov",
    type: "research",
  },
  {
    title: "Canadian News Articles Depicting Hospital Overcapacity & Influenza Strain Preceding Covid-19",
    content:
      "For years the alarms have been sounded about hospital overcrowding, patient transfers and delayed surgeries. Why now is it being used as justification for a lockdown, and why are regular citizens being blamed for it? The reality is the strain seen repeatedly within our hospitals is not a product of SARS-CoV-2, but rather a healthcare system fundamentally broken due to years of negligence and lack of resources.",
    link: "https://drive.google.com/file/d/1PjFQQAkJB-0l14dGiOO9t0cDHJNliZ4y/view",
    linkText: "https://drive.google.com",
    type: "discussion",
  },
  {
    title: "Pandemic driving more young people to seek mental health help",
    content:
      "Kids Help Phone says calls and texts from young people in Ottawa have more than doubled since schools closed for the holidays on Dec. 18, representing a 110 per cent increase over the same period last year. CHEO, eastern Ontario's children's hospital in Ottawa, is reporting a 20 to 30 per cent spike in mental health referrals since the pandemic began.",
    link:
      "https://www.cbc.ca/news/canada/ottawa/ottawa-youth-mental-health-covid-19-pandemic-2021-1.5875367?fbclid=IwAR0Y4B-nEmP5OXS2C2Z3bX3J0B0kvcQZG2kVvYK5vVRJngt-lnOB5sBsY78",
    linkText: "https://www.cbc.ca",
    type: "consequence",
  },
  {
    title: "Our public health orders don't apply to any levels of government",
    content:
      "Manitoba Chief Public Health Officer Dr. Brent Roussin states that our public health orders don't apply to any levels of government. So they don't apply to federal provincial, or municipal governments.",
    link: "https://twitter.com/punditclass/status/1351953027280719872",
    linkText: "https://twitter.com",
    type: "leader",
  },
  {
    title: "Hasenfratz off the province's vaccine task force after travelling over holidays",
    content:
      "Hasenfratz resigned from the task force Tuesday after it came to light she had travelled overseas over the holidays.",
    link:
      "https://www.guelphtoday.com/local-news/hasenfratz-off-the-provinces-vaccine-task-force-after-travelling-over-holidays-3276734?utm_source=kitchenertoday.com&utm_campaign=kitchenertoday.com&utm_medium=referral",
    linkText: "https://www.guelphtoday.com",
    type: "leader",
  },
  {
    title: "13 year old's letter asking Ford to do better",
    content:
      "A 13 year old wrote a letter to Ford explaining her anxiety about lockdown measures, saying “what you are doing to my generation is not okay. All I have left to do is sit at home staring at my computer. I cry a lot.”",
    link: "https://twitter.com/cumiastowski/status/1352776698408955906",
    linkText: "https://twitter.com",
    type: "consequence",
  },
  {
    title: "Paramedics sent home without pay",
    content:
      "Local paramedics who tested positive for COVID-19 were sent home to self-isolate without pay and their WSIB claims are getting rejected.",
    link:
      "https://kitchener.ctvnews.ca/video?clipId=2123315&binId=1.1147261&playlistPageNum=1#_gus&_gucid=&_gup=twitter&_gsc=CDTE1TG",
    linkText: "https://kitchener.ctvnews.ca",
    type: "consequence",
  },
  {
    title: "WHO information notice on PCR testing",
    content:
      "WHO's information notice states that careful interpretation of weak positive results is needed, where test results do not correspond with the clinical presentation, a new specimen should be taken and retested. The probability that a person who has a positive result is truly infected with SARS-CoV-2 decreases as prevalence decreases, irrespective of the claimed specificity.",
    link: "https://www.who.int/news/item/20-01-2021-who-information-notice-for-ivd-users-2020-05",
    linkText: "https://www.who.int",
    type: "discussion",
  },
  {
    title: "Elderly woman dies alone a month after birthday",
    content:
      "An elderly woman celebrates her 92nd birthday alone and in tears, missing her family. A month later she passed, not of COVID, but because of isolation.",
    link: "https://twitter.com/voicesofltc/status/1352353033947140097",
    linkText: "https://twitter.com",
    type: "consequence",
  },
  {
    title: "13 more Canadian doctors speak out against lockdown",
    content:
      "Research clearly shows that lockdowns have not reduced COVID hospitalizations, ICU admissions or deaths in North America or Europe. In fact, lockdowns have increased life-years lost by 1000%. There is no scientific or medical basis for lockdowns.",
    link: "https://twitter.com/NML_Canada/status/1353786106433703937/photo/1",
    linkText: "https://twitter.com",
    type: "expert",
  },
  {
    title: "CHEO issues plea to pandemic-stressed parents to seek help after spike in infant injuries",
    content:
      "In the past five months — some of the toughest of the pandemic — the hospital has treated 20 infants under one year old for injuries including head trauma and broken bones. Such injuries often coincide with high levels of parental stress. The numbers spiked in December and January. During the same period last year, the hospital treated eight infants with similar injuries.",
    link: "https://ottawacitizen.com/news/local-news/cheo-issues-plea-to-pandemic-stressed-parents-to-seek-help-after-spike-in-infant-injuries",
    linkText: "https://ottawacitizen.com",
    type: "consequence",
  },
  {
    title: "COVID19 Worldwide Data",
    content:
      "Particularly interesting is the data under 'lockdowns' which shows deaths per million versus lockdown stringency of countries with over 5000 deaths—the graph shows no correlation between the stringency of lockdowns and deaths.",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMGVjYjhkMjMtMzhjMy00OWRkLWJlNWItNjM0NzI0NjhiNTlkIiwidCI6IjlkZWYwNTBlLTExMDUtNDk1ZC1iNzUzLWRhOGRiZTc5MGVmNyJ9",
    linkText: "https://app.powerbi.com",
    type: "data",
  },
  {
    title: "Duration of Culturable SARS-CoV-2 in Hospitalized Patients with Covid-19",
    content:
      "Viral culture was positive only in samples with a cycle-threshold value of 28.4 or less. The incidence of culture positivity decreased with an increasing time from symptom onset and with an increasing cycle-threshold value.",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMc2027040",
    linkText: "https://www.nejm.org",
    type: "research",
  },
  {
    title: "We're getting self-harming 10-year-olds in A&E",
    content:
      "While they remain (for the most part) perplexingly immune to the health consequences of Covid-19, their lives and daily routines have been turned upside down. Children in mental health crisis used to be brought to A&E about twice a week. Since the summer it's been more like once or twice a day. Some as young as 10 have cut themselves, taken overdoses, or tried to asphyxiate themselves.",
    link: "https://www.bbc.com/news/health-55864573",
    linkText: "https://www.bbc.com",
    type: "consequence",
  },
  {
    title: "Incidence and Secondary Transmission of SARS-CoV-2 Infections in Schools",
    content:
      "We examined 11 school districts with nearly 100,000 students/staff open for 9 weeks of in person instruction, tracking secondary transmission of SARS-CoV-2; within-school infections were extremely rare.",
    link: "https://pediatrics.aappublications.org/content/pediatrics/early/2021/01/06/peds.2020-048090.full.pdf",
    linkText: "https://pediatrics.aappublications.org",
    type: "research",
  },
  {
    title: "Alberta man died from the lockdown, not COVID-19",
    content:
      "Dunham was told by a nurse at his Alberta doctor’s office that a procedure to get a defibrillator for his racing heart would not be going ahead because of COVID-19. He spent more time in intensive care as a result of having a heart attack that was preventable, than he would have receiving a defibrillator. Lambier says over the days she spent at Dunham’s side, one nurse cast doubts about the severity of the pandemic and told them they could take off their masks because “the hospital’s almost empty.'”",
    link: "https://calgaryherald.com/opinion/columnists/corbella-alberta-man-died-from-the-lockdown-not-covid-19",
    linkText: "https://calgaryherald.com",
    type: "consequence",
  },
  {
    title: "Opioid-related deaths are on the rise",
    content:
      "A recent report by Public Health Ontario showed that 695 people in Ontario died of a confirmed or suspected opioid-related death in the first 15 weeks of the pandemic alone - a 38 per cent increase compared to 15 weeks before the pandemic. Millions who were already at-or-near poverty have suddenly lost incomes, tourism has dropped off depriving communities of hard currency, oil prices have collapsed, and the loss of money coming from family members working overseas are all fueling the crisis.",
    link: "https://www.ctvnews.ca/health/i-ve-never-seen-numbers-like-this-opioid-related-deaths-are-on-the-rise-1.5255596",
    linkText: "https://www.ctvnews.ca",
    type: "consequence",
  },
  {
    title: "130 Million People Could Starve Because of the Lockdowns",
    content:
      "A chief economist with the United Nation's World Food Program warns than an estimated 265 million people around the globe could face starvation by the end of the year",
    link: "https://townhall.com/tipsheet/bronsonstocking/2020/04/22/so-130-million-people-could-starve-because-of-the-lockdowns-n2567446",
    linkText: "https://townhall.com",
    type: "consequence",
  },
  {
    title: "Long-term loneliness could raise Alzheimer’s risk among the elderly",
    content:
      "Months of social isolation could lead to a sharp increase in the rate of Alzheimer’s disease among the elderly, researchers said.",
    link: "https://www.thetimes.co.uk/article/long-term-loneliness-could-raise-alzheimer-s-risk-among-the-elderly-sbbrfdx3z",
    linkText: "https://www.thetimes.co.uk",
    type: "consequence",
  },
  {
    title: "Canada COVID-19 Weekly Epidemiology Report",
    content:
      "Total number of COVID-19 outbreaks, cases, and deaths by outbreak setting (page 12 of report) shows that almost all outbreaks are in care homes, followed by prisons, industrial settings and hospitals—yet the entire population must be blamed and shamed to support atrocious policies that spend money we do not have on things that make no difference to this problem.",
    link: "https://www.canada.ca/content/dam/phac-aspc/documents/services/diseases/2019-novel-coronavirus-infection/surv-covid19-weekly-epi-update-20210305-en.pdf",
    linkText: "https://www.canada.ca",
    type: "data",
  },
  {
    title: "Thousands of small businesses consider shutting down",
    content:
      "181,000 Canadian small business owners now contemplating pulling the plug, putting 2.4 million jobs at risk. Businesses in the hospitality (restaurants, hotels, caterers) and arts and recreation (gyms, venues, arcades) sectors are most at risk, with roughly one in three businesses in both sectors actively considering closure.",
    link: "https://www.cfib-fcei.ca/en/media/news-releases/181000-canadian-small-business-owners-now-contemplating-pulling-plug-putting-24",
    linkText: "https://www.cfib-fcei.ca",
    type: "consequence",
  },
  {
    title: "Calling for benefit–risk evaluations of COVID-19 control measures",
    content:
      "We think government lockdowns cause substantial collateral health damage. Government restrictions are disrupting traditional means of support between friends and family members. Physical distancing and contact reduction are causing severe stress to many people and might increase the risk of suicide. In a meta-analysis of the prevalence of stress, anxiety, depression among the general population during the COVID-19 pandemic, the prevalence of depression in the months of the pandemic up to May, 2020, was 33.7%",
    link: "https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(21)00193-8/fulltext",
    linkText: "https://www.thelancet.com",
    type: "expert",
  },
  {
    title: "Almost a Year Later, There’s Still No Evidence Showing Governments Can Control the Spread of Covid-19",
    content:
      "It is very unlikely that health officials will start pointing to seasonality as an alternative explanation for our continually improving numbers. To do so would be a tacit admission that nearly a year's worth of heavily politicized behavioral mandates, life-destroying lockdowns, and devastating business closures were all for naught. But the data have spoken, and it is abundantly clear that attempting to socially engineer a respiratory virus out of existence is nothing short of a fool's errand.",
    link: "https://mises.org/wire/almost-year-later-theres-still-no-evidence-showing-governments-can-control-spread-covid-19",
    linkText: "https://mises.org",
    type: "discussion",
  },
  {
    title: "Experts call Peel guidelines to place children in solitary quarantine 'cruel punishment'",
    content:
      "“I don’t understand how any health-care professional has moved so far away from the fundamentals of public health and of doing no harm that they would think that basically incarcerating a child in a room for 14 days is in any way justified,” said Dr. Martha Fulford, an infectious diseases physician at Hamilton Health Science who focuses on pediatrics. “This is shocking,” adds Fulford, “especially when you consider this is being proposed for children who are not in any way sick.”",
    link: "https://torontosun.com/news/provincial/experts-call-peel-guidelines-to-place-children-in-solitary-quarantine-cruel-punishment",
    linkText: "https://torontosun.com",
    type: "discussion",
  },
  {
    title: "Lockdowns have killed millions",
    content:
      "While almost all the people who have died of covid have died in rich countries and been old, the vast majority of people who have died of lockdown have died in poor countries and been young. This means that the number of years of life lost to lockdown is many times greater than the number of years of life lost to covid-19. These unintended consequences of the efforts to fight covid have caused the rate of childhood deaths to increase in 2020 for the first time in decades. Rates of childhood marriage and of teen pregnancy and abortion have increased significantly as a result of taking children out of school. Lockdowns are inherently racist and elitist, with unclear benefits but proven harms.",
    link: "https://sebastianrushworth.com/2021/03/01/lockdowns-have-killed-millions/",
    linkText: "https://sebastianrushworth.com",
    type: "consequence",
  },
  {
    title: "Social regulation of gene expression in human leukocytes",
    content:
      "Individuals who experience themselves as chronically isolated from others have an increased risk of several inflammation-related diseases. Several functionally distinct subsets of immune response genes showed selective under-expression, including type I interferon response genes involved in innate antiviral resistance, and genes supporting antibody production and mature B lymphocyte function.",
    link: "https://genomebiology.biomedcentral.com/articles/10.1186/gb-2007-8-9-r189",
    linkText: "https://genomebiology.biomedcentral.com",
    type: "research",
  },
  {
    title: "What went wrong in Canada's Pandemic Response",
    content:
      "David Redman speaks out against lockdown measures. He has several years of experience in logistics operations and is the former head of emergency management in Alberta. He states that back in march we should have immediately looked into extra infrastructure and staffing issues, and planned accordingly. The concept of surge capacity was completely ignored right at the beginning of this pandemic and emergency management organizations were sidelined. Evidence from previous pandemics and experts were ignored, leaving us with unprecedented and damaging lockdown measures.",
    link: "https://www.youtube.com/watch?v=cKGr5Ks-euM",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Mortality in Norway and Sweden before and after the Covid-19 outbreak: a cohort study",
    content:
      "All-cause mortality remained unaltered in Norway. In Sweden, the observed increase in all-cause mortality during Covid-19 was partly due to a lower than expected mortality preceding the epidemic and the observed excess mortality, was followed by a lower than expected mortality after the first Covid-19 wave. This may suggest mortality displacement.",
    link: "https://www.medrxiv.org/content/10.1101/2020.11.11.20229708v1.full",
    linkText: "https://www.medrxiv.org",
    type: "research",
  },
  {
    title: "Believing in impossible things – and COVID19",
    content:
      "Dr. Malcolm Kendrick describes the contradictory nature of lockdown measures and how lockdowns do not work as we expected. In the top four non-lockdown countries, the death rate is, on average 391 per million. In the top twenty lockdown countries, the death rate is, on average 1,520 per million.",
    link: "https://drmalcolmkendrick.org/2021/03/06/believing-in-impossible-things-and-covid19/",
    linkText: "https://drmalcolmkendrick.org",
    type: "expert",
  },
  {
    title: "Dr. Fauci on asymptomatic transmission",
    content:
      "In all the history of respiratory-borne viruses of any type, asymptomatic transmission has never been the driver of outbreaks. The driver of outbreaks is always a symptomatic person. An epidemic is not driven by asymptomatic carriers.",
    link: "https://www.youtube.com/watch?v=JEI_a764XPM",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Dr Richard Urso on the ineffectiveness of lockdowns",
    content:
      "We've never locked down healthy people, there's no science behind that. It's a seasonal illness, and as the seasonality of it was brought to fore, they ended up having much, much more deaths despite lockdowns. We are hurting people, and making them less healthy—why do we want to keep healthy people locked up in their home, not getting sunlight and exercise, doesn't make sense.",
    link: "https://www.youtube.com/watch?v=l06Bx-xlNIk",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "Temperature and Latitude Correlate with SARS-CoV-2",
    content:
      "Our worldwide analysis of epidemiological data collected for 211 countries (April 15, 2020) suggests that there is a significant negative correlation between environmental temperature and normalized COVID-19 epidemiological data, including disease incidence, mortality, recovery rate and the number of active cases.",
    link: "https://journals.sagepub.com/doi/full/10.1177/1176934321989695",
    linkText: "https://journals.sagepub.com",
    type: "research",
  },
  {
    title: "Stanford Doctor Calls Lockdowns the 'Biggest Public Health Mistake We've Ever Made'",
    content:
      "Lockdowns themselves impose great harm on people. Lockdowns are not a natural normal way to live. People who are poor face much more hardship from the lockdowns than people who are rich. At the same time, they have not served to control the epidemic in the places where they have been most vigorously imposed. In the US, they have—at best—protected the 'non-essential' class from COVID, while exposing the essential working class to the disease.",
    link: "https://www.newsweek.com/stanford-doctor-calls-lockdowns-biggest-public-health-mistake-weve-ever-made-1574540",
    linkText: "https://www.newsweek.com",
    type: "expert",
  },
  {
    title: "Stay-at-home policy is a case of exception fallacy: an internet-based ecological study",
    content:
      "A recent mathematical model has suggested that staying at home did not play a dominant role in reducing COVID-19 transmission. With our results, we were not able to explain if COVID-19 mortality is reduced by staying at home in ~ 98% of the comparisons.",
    link: "https://www.nature.com/articles/s41598-021-84092-1",
    linkText: "https://www.nature.com",
    type: "research",
  },
  {
    title: "Interview with Dr. Mark Trozzi on PCR and Lockdown",
    content:
      "Dr. Mark Trozzi, with over 25 years of practise in the field, describes how the PCR test is not supposed to be used as a diagnostic tool, how lockdowns are damaging and how the danger from COVID is not what the media makes it out to be.",
    link: "https://www.youtube.com/watch?v=HR8jD7Zki5Q",
    linkText: "https://www.youtube.com",
    type: "expert",
  },
  {
    title: "COVID-19 Lockdown Policies: An Interdisciplinary Review",
    content:
      "Lockdowns are associated with reduced mortality in epidemiological modelling studies but not in studies based on empirical data from the Covid-19 pandemic. Psychological research supports the proposition that lengthy lockdowns may exacerbate stressors such as social isolation and unemployment that have been shown to be strong predictors of falling ill if exposed to a respiratory virus. Studies at the economic level of analysis points to the possibility that deaths associated with economic harms or underfunding of other health issues may outweigh the deaths that lockdowns save, and that the extremely high financial cost of lockdowns may have negative implications for overall population health.",
    link: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3782395",
    linkText: "https://papers.ssrn.com",
    type: "research",
  },
  {
    title: "Medical expert sound alarm over ‘collateral damage’ of lockdowns",
    content:
      "Infectious diseases specialist Dr. Martha Fulford shares insight on the collateral damage caused by lockdowns in the fight against COVID-19.",
    link: "https://globalnews.ca/video/7748177/medical-expert-sound-alarm-over-collateral-damage-of-lockdowns/",
    linkText: "https://globalnews.ca",
    type: "expert",
  },
  {
    title: "The Social Distancing Imposed To Contain COVID-19 Can Affect Our Microbiome",
    content:
      "Here, we suggest that the disruption in microbial sharing, as it is associated with dysbiosis (loss of bacterial diversity associated with an imbalance of the microbiota with deleterious consequences for the host), may worsen the prognosis of COVID-19 disease.",
    link: "https://msphere.asm.org/content/5/5/e00716-20",
    linkText: "https://msphere.asm.org",
    type: "research",
  },
  {
    title: "'It's beyond appalling:' Ontario long-term care home residents beg for release from COVID-19 confinement",
    content:
      "Some compared their situations to solitary confinement, and urged the provincial government to act on what they called a gross violation of their basic human rights. They also point to evidence that extreme isolation is physically and mentally damaging, especially to residents of nursing homes, many of whom suffer cognitive difficulties and need familiar faces and touch.",
    link: "https://www.cp24.com/news/it-s-beyond-appalling-ontario-long-term-care-home-residents-beg-for-release-from-covid-19-confinement-1.5368555",
    linkText: "https://www.cp24.com",
    type: "discussion",
  },
  {
    title: "Three prominent docs call lockdowns a waste of time",
    content:
      "Alexander, a PhD educated in epidemiology who recently worked for the Health and Human Services Department in Washington, D.C. as a senior advisor on COVID-19 pandemic policy, said they have a year’s worth of data showing there have been “crushing harms” from the “draconian” lockdowns.",
    link: "https://torontosun.com/news/local-news/levy-three-prominent-docs-call-lockdowns-a-waste-of-time",
    linkText: "https://torontosun.com",
    type: "expert",
  },
  {
    title: "Pediatricians sound alarm over mental health crisis in youth",
    content:
      "Liddle is among a group of local pediatricians sounding the alarm about the rise of mental health issues among children, adolescents and teens — psychological effects of the pandemic they fear will linger after the health crisis is over.",
    link: "https://www.cbc.ca/amp/1.6002633?fbclid=IwAR39shzb0nGDDdBXJAc50vupfhxyaQm3WddqxeGx1G9Jot3wUDAYHY0J648",
    linkText: "https://www.cbc.ca",
    type: "consequence",
  },
  {
    title: "Lockdowns hurt child speech and language skills - report",
    content:
      "Data from 50,000 pupils and a survey of schools across England have shown an increased number of four- and five-year-olds needing help with language. Evidence shows poor speech development can have long-term effects on learning.",
    link: "https://www.bbc.com/news/education-56889035",
    linkText: "https://www.bbc.com",
    type: "consequence",
  },
];

export default content;
