import "./styles.css";
import { useHistory } from "react-router-dom";

function Mission() {
  const history = useHistory();

  return (
    <section className="pt-5 mission-page-wrapper section is-flex is-flex-direction-column is-justify-content-space-between">
      <div className="back-button">
        <button onClick={() => history.goBack()} className="trans-button button is-small">
          <i className="link-icon fas fa-arrow-left" aria-hidden="true"></i>
          go back
        </button>
      </div>
      <div className="container inner-container my-4">
        <h1 className="title mb-0">Mission</h1>
        <div className="hr-block mb-5 mt-1" />
        <p>
          Our mission is to encourage people to question the efficacy of the current lockdown measures in place. To
          think critically about it's affects on our mental health, physical health, and our economy—particularly the
          many small businesses of Ontario.
        </p>
        <p>
          We want you to be responsible for your own health, to be able to make decisions regarding your well-being
          without the government interfering with daily menial tasks like whether or not you want to visit a friend,
          walk into a small business, where you happen to be driving, or how many people you're allowed to have in the
          privacy of your own home.
        </p>
        <p>
          We want the government to stop blaming it's own citizens for it's lack of preparedness, for it's failures in
          managing our healthcare system, and for it's ineptitude in managing COVID-19 by implementing a non-evidence
          based, undemocratic blanket lockdown that does not work, and only causes further damage.
        </p>
        <p className="mb-6">
          We want to stop the thoughtless blanket lockdown and step back from the myopic focus on cases without
          exception to any other health problem, economic problem, or individual need.
        </p>
        <h1 className="title mb-0">Are there better options?</h1>
        <div className="hr-block mb-5 mt-1" />
        <p>
          Yes, there is a better solution—any solution, however, would be better than the current lockdown measures.
          Many of the articles on this website include suggestions and offer ideas that would be an improvement on the
          current lockdown.
        </p>
        <p>
          For example, the Great Barrington Declaration, spear-headed by Dr. Martin Kulldorff (professor of medicine at
          Harvard University, an epidemiologist with expertise in infectious disease outbreaks) recommends a focused
          protection approach that balances the risks and benefits of reaching herd immunity while better protecting
          those who are at highest risk. Several Ontario doctors are voicing this same sentiment, calling for a more
          balanced approach to restrictions, rather than introducing more harmful lockdown measures. They suggest
          throwing more resources at the health care system, creating capacity and building more field hospitals—such as
          the one created in Halton Region.
        </p>
        <p>
          At the very least, we shouldn't be hearing news about nurses not receiving their pandemic pay, or nurses not
          getting paid sick leave. At the very least, Doug Ford should be pulling through with his promise of building
          an "iron ring" of protection around long-term care homes, which still continue to be the leading source of
          COVID-19 deaths. At the very least, our government shouldn't be closing benign and safe activities like a
          drive-through light show, or a toboggan hill, while their politicians and health officials travel cross-border
          with their families and hypocritically tell us to stay home and away from our families. At the very least, our
          government should stop blaming us for it's unpreparedness and ineptitude, especially since we are already over one
          year into this.
        </p>
      </div>
      <div />
    </section>
  );
}

export default Mission;
