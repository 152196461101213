import "./styles.css";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { htmlToText } from "html-to-text"

function ContactMPP() {
  const history = useHistory();
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [MPP, setMPP] = useState("");
  const [mppEmail, setMppEmail] = useState("");
  const [name, setName] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyFailed, setCopyFailed] = useState(false);
  const emailContainer = useRef(null);

  const formattedText = () => {
    let textToCopy = emailContainer.current.innerHTML;
    textToCopy = textToCopy.replaceAll('[MPP\'s name]', '');
    textToCopy = textToCopy.replaceAll('[your name]', '');
    textToCopy = textToCopy.replaceAll(' ,', ',');

    return htmlToText(textToCopy, {
      wordwrap: false,
      tags: {
        'a': { options: { noLinkBrackets: true } },
      }
    });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(formattedText())
    .then(() => {
      setCopyFailed(false)
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false)
      }, 1500)
    })
    .catch((error) => {
      setCopyFailed(true)
      setTimeout(() => {
        setCopyFailed(false)
      }, 1500)
      console.log(`Copy failed! ${error}`)
    })
  };

  const findMPP = async () => {
    setPostalCodeError(false);
    const formattedPostalcode = postalCode.trim().toUpperCase().replace(/\W/g, "").replace(/\s/g, "");
    const voterInfoUrl = `https://kdev.test/api/mpp/${formattedPostalcode}`;

    try {
      let voterInfoResponse = await fetch(voterInfoUrl);
      const voterInfo = await voterInfoResponse.json();
      const mppName = voterInfo?.representatives_centroid.find(
        (centroid) => centroid.representative_set_name === "Legislative Assembly of Ontario"
      )?.name;
      const mppEmail = voterInfo?.representatives_centroid.find(
        (centroid) => centroid.representative_set_name === "Legislative Assembly of Ontario"
      )?.email;

      if (!mppName) {
        setPostalCodeError(true);
        return;
      }

      setMPP(mppName);
      mppEmail && setMppEmail(mppEmail);
    } catch (error) {
      console.log("Could not find voter info based on postal code.", error);
      setPostalCodeError(true);
    }
  };

  return (
    <section className="pt-5 mission-page-wrapper section is-flex is-flex-direction-column is-justify-content-space-between">
      <div className="back-button">
        <button onClick={() => history.goBack()} className="trans-button button is-small">
          <i className="link-icon fas fa-arrow-left" aria-hidden="true"></i>
          go back
        </button>
      </div>
      <div className="container inner-container my-4">
        <h1 className="title mb-0">Contact Your MPP</h1>
        <div className="hr-block mb-5 mt-1" />
        <p>
          Enter your postal code below to find your MPP, fill out your name, click "Copy letter" (or manually select and copy) and send it to your MPP
          to let them know that you are not in favour of this lockdown. You can also click{" "}
          <a target="_blank" rel="noreferrer" href="https://www.ola.org/en/members/current">
            here
          </a>{" "}
          to find a list of MPPs, or visit{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.elections.on.ca/en/voting-in-ontario/electoral-districts.html"
          >
            this link
          </a>{" "}
          instead to search by postal code, and enter the details yourself below.
        </p>
        <div className="mb-5">
          <input
            value={postalCode}
            onChange={({ target: { value } }) => setPostalCode(value)}
            type="text"
            className="input mb-2"
            placeholder="Postal code"
          />
          <button onClick={() => findMPP()} className="mr-1 trans-button button is-small">
            <i className="link-icon fas fa-search mr-1" aria-hidden="true"></i>
            Look up
          </button>
          {postalCodeError && (
            <span className="has-text-danger is-size-7">
              <i className="ml-1 mr-0 link-icon fas fa-exclamation-triangle" aria-hidden="true"></i> Could not find MPP,
              try again or find them through the above links
            </span>
          )}
        </div>
        <input
          value={MPP}
          onChange={({ target: { value } }) => setMPP(value)}
          type="text"
          className="input mb-5"
          placeholder="MPP's name"
        />
        <input
          value={mppEmail}
          onChange={({ target: { value } }) => setMppEmail(value)}
          type="text"
          className="input mb-5"
          placeholder="MPP's email"
        />
        <input
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          type="text"
          className="input mb-5"
          placeholder="Your name"
        />
        <div className="buttonContainer is-relative">
          <span className={`has-text-success is-size-7 ${copySuccess ? 'clipboard-action' : ''}`}>
            <i className="has-text-success ml-1 mr-1 link-icon fas fa-check" aria-hidden="true"></i>copied to clipboard
          </span>
          <span className={`has-text-danger is-size-7 ${copyFailed ? 'clipboard-action' : ''}`}>
            <i className="has-text-danger ml-1 mr-1 link-icon fas fa-exclamation-triangle" aria-hidden="true"></i>copy failed
          </span>
          <button onClick={() => copyToClipboard()} className="mb-2 mr-2 trans-button button is-small">
            <i className="link-icon far fa-copy mr-1" aria-hidden="true"></i>
            Copy letter
          </button>
        </div>
        <div ref={emailContainer} className="emailContainer">
          <p>Hello {MPP || "[MPP's name]"},</p>
          <p>
            I'm writing to you, the MPP for my riding, to bring up concerns regarding the lockdown measures in place
            here in Ontario. With more Ontario doctors and politicians speaking out against the efficacy of lockdown and
            its detrimental effects on not just our health, but on our many small businesses, I'm worried that extending
            these blanket lockdown measures further will cause much more damage.
          </p>
          <p>
            Recent evidence—and the opinions of several medical experts—has demonstrated that lockdowns are not the
            correct solution moving forward. I'm sure you're well aware of the recent letter from Dr. Richard Schabas,
            former Ontario Chief Medical Officer, sent to Ford in favour of ending lockdowns (you can read it by
            clicking{" "}
            <a target="_blank" rel="noreferrer" href="https://twitter.com/OnCall4ON/status/1351219150945976328">
              here
            </a>
            ). There was another article written by Dr. Matt Strauss (former medical director of the critical care unit
            at Guelph General Hospital, current assistant professor of medicine at Queen’s University) in which he
            speaks out against lockdown, stating that "if lockdowns were a prescription drug for Covid-19 treatment, the
            FDA would never have approved it" (you can read it by clicking{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.spectator.co.uk/article/a-medic-s-case-against-another-lockdown"
            >
              here
            </a>
            ). A few months ago, several doctors also sent a letter to Ford: 20 doctors and professors of medicine from
            faculties at the University of Toronto, McMaster University, University of Ottawa and from hospitals such as
            Sick Kids, reached out to Premier Doug Ford arguing against a wholesale return to a lockdown as a way to
            deal with rising COVID-19 cases (you can read it by clicking{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.healthing.ca/opinion/columnists/doctors-urge-localized-approach-not-sweeping-lockdown-in-open-letter-to-doug-ford/wcm/2a19968b-6421-4707-9e80-2112cddeba3f"
            >
              here
            </a>
            ). Another 13 Canadian doctors also sent a letter to Ford, stating that "there is no scientific or medical
            basis for lockdowns" and explained that "government policies are having a horrific effect on the mental and
            physical health of Ontario's citizens" (you can read it by clicking{" "}
            <a target="_blank" rel="noreferrer" href="https://twitter.com/NML_Canada/status/1353786106433703937">
              here
            </a>
            ). David Redman, who has several years of experience in logistics operations and is the former head of emergency management in Alberta, outlines in his presentation (which you can view by clicking <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=cKGr5Ks-euM">here</a>) what went wrong in our pandemic response, and how what we did (and are doing) was absolutely not the right approach: evidence from previous pandemics and experts were ignored, leaving us with unprecedented and damaging lockdown measures. Lockdown is not something that is unequivocally supported by the health experts—and I'm hoping that you
            too will join these experts in helping to stop the damaging lockdown measures we have in place here in
            Ontario.
          </p>
          <p>
            Research by Dr. Ari Joffe—a specialist in pediatric infectious diseases at the Stollery Children’s Hospital
            in Edmonton and a Clinical Professor in the Department of Pediatrics at University of Alberta—found that
            lockdown harms are 10 times greater than benefits (which you can read by clicking{" "}
            <a target="_blank" rel="noreferrer" href="https://www.preprints.org/manuscript/202010.0330/v2">
              here
            </a>
            ). The Canadian Federation of Independent Business (CFIB) is reporting that 181,000 Canadian small business
            owners are contemplating pulling the plug, putting 2.4 million jobs at risk, with thousands of small
            businesses already having permanently closed in the last year (you can read their article{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.cfib-fcei.ca/en/media/news-releases/181000-canadian-small-business-owners-now-contemplating-pulling-plug-putting-24"
            >
              here
            </a>
            ). The toll on mental health, especially of our children, is devastating. CHEO, eastern Ontario's children's
            hospital in Ottawa, is reporting a 20 to 30 percent spike in mental health referrals since the pandemic
            began (news article{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.cbc.ca/news/canada/ottawa/ottawa-youth-mental-health-covid-19-pandemic-2021-1.5875367?fbclid=IwAR0Y4B-nEmP5OXS2C2Z3bX3J0B0kvcQZG2kVvYK5vVRJngt-lnOB5sBsY78"
            >
              here
            </a>
            ). A young girl recently wrote a letter to Ford stating "what you are doing to my generation is not okay.
            All I have left to do is sit at home staring at my computer. I cry a lot" (you can view it{" "}
            <a target="_blank" rel="noreferrer" href="https://twitter.com/cumiastowski/status/1352776698408955906">
              here
            </a>
            ).
          </p>
          <p>
            I'm not writing this email to try to spread misinformation, or share some unbased conspiracy theory. The
            evidence showing the ineffectiveness of lockdowns, it's encroachment on our freedoms, and the documented
            damage it is causing is growing. I have real doubts about the efficacy of this lockdown, and I am gravely
            concerned about the damage it is causing. I understand you may be in full support of lockdown, but I hope
            you can understand that, for people like myself—along with the many others who have already spoken out—it is
            having detrimental effects. I want to add my name to the list of people of which lockdown has negatively
            affected, and I hope you can have some sympathy and take another look at what this lockdown is truly doing
            to us.
          </p>
          <p>
            Thank you for taking the time to read this {MPP || "[MPP's name]"}, I wish you good health and hope that you
            continue serving the needs of our community.
          </p>
          <p>{name || "[your name]"}</p>
        </div>
      </div>
    </section>
  );
}

export default ContactMPP;
